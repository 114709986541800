<template>
  <h3 class="text-light mt-3">Editar Evento</h3>

  <form @submit.prevent="onEditEvento" class="pronosticoForm">
    <div class="text-end text-light">
      <button
        type="button"
        class="btnEliminarTable"
        @click="irPronosticosEditar()"
      >
        X
      </button>
    </div>
    <div class="row">
      <div class="col-3">
        <label class="text-light">Evento</label>
        <input type="text" class="form-control" v-model="objEvento.nombre" />
      </div>
      <div class="col-3">
        <label class="text-light">Fecha inicio</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="objEvento.fecha_ini"
        />
      </div>
      <div class="col-3">
        <label class="text-light">Fecha fin</label>
        <input
          type="datetime-local"
          class="form-control"
          v-model="objEvento.fecha_ini"
        />
      </div>
      <div class="col-3">
        <label for="" class="text-light">Competidores</label>
        <div>
          <Multiselect
            mode="tags"
            v-model="objEvento.competidores"
            :close-on-select="false"
            :searchable="true"
            :create-option="true"
            :options="arrCompetidores"
          />
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-md-2">
        <button type="submit" class="myButtonAceptar">Guardar</button>
      </div>
    </div>
  </form>
</template>

<script>
import { onMounted, reactive, ref } from "vue-demi";
import usePronostico from "../composable/usePronostico";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Multiselect from "@vueform/multiselect";
import useUtil from "../../../use/useUtil";
import Swal from "sweetalert2";
export default {
  components: {
    Multiselect,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const {
      findEvento,
      lstEvento,
      getCompetidores,
      lstCompetidores,
      editEvento,
    } = usePronostico();
    const store = useStore();
    const { dateFormatInput, timeFormatInput } = useUtil();
    const arrCompetidores = ref([]);
    const objEvento = reactive({
      id: null,
      nombre: "",
      resultado: "",
      fecha_ini: null,
      fecha_fin: null,
      competidores: [],
    });

    onMounted(async () => {
      store.commit("showLoader");
      let id = route.params["id"];
      await findEvento(id);
      await getCompetidores({
        deporte_id: lstEvento.value.competidores[0].deporte_id,nombre: ""
      });

      await lstCompetidores.value.forEach((element) => {
        let obj = {
          value: element.id,
          label: element.nombre,
        };
        arrCompetidores.value.push(obj);
      });

      lstEvento.value.competidores.forEach((element) => {
        objEvento.competidores.push(element.id);
      });

      objEvento.id = lstEvento.value.evento.id;
      objEvento.nombre = lstEvento.value.evento.nombre;
      objEvento.resultado = lstEvento.value.evento.resultado;

      objEvento.fecha_ini =
        dateFormatInput(lstEvento.value.evento.fecha_ini) +
        "T" +
        timeFormatInput(lstEvento.value.evento.fecha_ini);
      objEvento.fecha_fin =
        dateFormatInput(lstEvento.value.evento.fecha_fin) +
        "T" +
        timeFormatInput(lstEvento.value.evento.fecha_fin);

      store.commit("hideLoader");
    });

    const onEditEvento = async () => {
      store.commit("showLoader");

      let resp = await editEvento(objEvento);

      if (resp.code == 200) {
        Swal.fire("Excelente!", resp.message, "success");
      } else {
        Swal.fire("Error!", resp.message, "error");
      }
      router.push("/pronosticos-editar/" + resp.data.pronostico_id);

      store.commit("hideLoader");
    };

    const irPronosticosEditar = () => {
      router.push(
        "/pronosticos-editar/" + lstEvento.value.evento.pronostico_id
      );
    };

    return {
      lstEvento,
      objEvento,
      arrCompetidores,
      onEditEvento,
      irPronosticosEditar,
    };
  },
};
</script>

<style scoped>
.pronosticoForm {
  background: #212529;
  -webkit-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 28px 17px rgba(0, 0, 0, 0.75);
  padding: 20px;
  width: 100%;
  margin: auto;
}
</style>